const initMore = () => {
  const more = document.querySelector<HTMLButtonElement>('.about__more');
  const texts = document.querySelector<HTMLDivElement>('.about__texts');

  more?.addEventListener('click', () => texts?.classList.add('active'));
};

export default {
  init: () => {
    initMore();
  },
};
