import SmoothScroll from 'smooth-scroll';

const header = document.querySelector('.header');
const openButton = document.querySelector('.header__open');
const closeButton = document.querySelector('.header__close');

const initMobileMenu = () => {
  openButton?.addEventListener('click', () => {
    header?.classList.add('header--open');
  });
  closeButton?.addEventListener('click', () => {
    header?.classList.remove('header--open');
  });
};

const initNavButtons = () => {
  const buttons = document.querySelectorAll<HTMLAnchorElement>(
    '.header__nav-item'
  );
  buttons.forEach((item) =>
    item.addEventListener('click', () => {
      header!.classList.remove('header--open');
      document.body.classList.remove('overflow-hidden');
    })
  );
};

const initSmoothScroll = () => {
  let scroll = new SmoothScroll('a[href*="#"]');
};

export default {
  init: () => {
    initMobileMenu();
    initNavButtons();
    initSmoothScroll();
  },
};
