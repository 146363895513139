import Swiper, { Navigation, Pagination } from 'swiper';
import GLightbox from 'glightbox';

Swiper.use([Navigation, Pagination]);

const initRoomsSlider = () => {
  new Swiper('.rooms__slider .swiper-container', {
    loop: true,
    spaceBetween: 12,
    slidesPerView: 1,
    centeredSlides: true,

    pagination: {
      el: '.rooms__slider .swiper-pagination',
    },

    navigation: {
      nextEl: '.rooms__slider .swiper-button-next',
      prevEl: '.rooms__slider .swiper-button-prev',
    },
  });
};

const initZoom = () => {
  const sliders = document.querySelectorAll<HTMLDivElement>('.rooms__slider');
  sliders.forEach((slider) => {
    const slides = slider.querySelectorAll<HTMLDivElement>('.swiper-slide');

    const items = Array.from(slides)
      .filter((item) => !item.classList.contains('swiper-slide-duplicate'))
      .map((item) => ({ href: item.firstElementChild?.getAttribute('href') }));

    const glightbox = GLightbox({
      elements: items,
      loop: true,
    });

    slides.forEach((slide) => {
      slide.firstElementChild?.addEventListener('click', (event) => {
        event.preventDefault();
        glightbox.openAt(slide.dataset.swiperSlideIndex);
      });
    });

    // const id = slider.dataset.id;
    // GLightbox({
    //   selector: `.rooms__slider[data-id="${id}"] .swiper-slide:not(.swiper-slide-duplicate) .rooms__slide`,
    //   loop: true,
    // });
  });
};

const initMore = () => {
  const items = document.querySelectorAll('.rooms__item');
  const moreButton = document.querySelector('.rooms__more');
  moreButton?.addEventListener('click', () => {
    items.forEach((item) => item.classList.remove('hidden'));
    moreButton.parentElement!.style.display = 'none';
  });
};

export default {
  init: () => {
    initRoomsSlider();
    initZoom();
    initMore();
  },
};
