function getCookie(name: string) {
  let matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
        '=([^;]*)'
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

function setCookie(name: string, value: string, options: any = {}) {
  const date = new Date(Date.now() + 365 * 86400e3);
  const dateUTC = date.toUTCString();

  options = {
    path: '/',
    expires: dateUTC,
    ...options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie =
    encodeURIComponent(name) + '=' + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += '; ' + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += '=' + optionValue;
    }
  }

  document.cookie = updatedCookie;
}

const initCookie = () => {
  const cookieEl = document.querySelector<HTMLDivElement>('.cookie');
  const button = document.querySelector<HTMLButtonElement>('.cookie__button');
  const close = document.querySelector<HTMLButtonElement>('.cookie__close');

  button?.addEventListener('click', () => {
    setCookie('cookie_notification', 'confirmed');
    cookieEl?.classList.remove('active');
  });

  close?.addEventListener('click', () => {
    cookieEl?.classList.remove('active');
  });

  if (!getCookie('cookie_notification')) {
    cookieEl?.classList.add('active');
  }
};

export default {
  init: () => {
    initCookie();
  },
};
