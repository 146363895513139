const initCallbackButton = () => {
  const modal = document.querySelector<HTMLDivElement>('.modal-form');
  const button = document.querySelector<HTMLButtonElement>('.footer__callback');

  button?.addEventListener('click', () => {
    modal?.classList.add('active');
  });
};

const initPolicy = () => {
  const modal = document.querySelector<HTMLDivElement>('.modal-policy');
  const button = document.querySelector<HTMLButtonElement>('.footer__policy');
  button?.addEventListener('click', () => modal?.classList.add('active'));

  const policyButtons = document.querySelectorAll('.policy__button');
  policyButtons.forEach((item) =>
    item.addEventListener('click', () => modal?.classList.add('active'))
  );
};

const initRules = () => {
  const modal = document.querySelector<HTMLDivElement>('.modal-rules');
  const button = document.querySelector<HTMLButtonElement>('.footer__rules');
  button?.addEventListener('click', () => modal?.classList.add('active'));
};

const initCookie = () => {
  const modal = document.querySelector<HTMLDivElement>('.modal-cookie');
  const button = document.querySelector<HTMLButtonElement>('.footer__cookie');
  button?.addEventListener('click', () => modal?.classList.add('active'));

  const cookieButton = document.querySelector<HTMLAnchorElement>(
    '.cookie__link'
  );
  cookieButton?.addEventListener('click', (event) => {
    event.preventDefault();
    modal?.classList.add('active');
  });
};

export default {
  init: () => {
    initCallbackButton();
    initPolicy();
    initRules();
    initCookie();
  },
};
