const initModals = () => {
  const modals = document.querySelectorAll<HTMLDivElement>('.modal');
  modals.forEach((item) => {
    item.addEventListener('click', (event) => {
      if (event.target === event.currentTarget) {
        item.classList.remove('active');
        document.body.classList.remove('overflow-hidden');
      }
    });

    const closeButton = item.querySelector<HTMLButtonElement>('.modal__close');
    closeButton?.addEventListener('click', () => {
      item.classList.remove('active');
      document.body.classList.remove('overflow-hidden');
    });
  });
};

export default {
  init: () => {
    initModals();
  },
};
