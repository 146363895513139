import Swiper, { Navigation, Pagination } from 'swiper';
import noUiSlider from 'nouislider';

Swiper.use([Navigation, Pagination]);

const initReviewsSlider = () => {
  new Swiper('.reviews__slider .swiper-container', {
    loop: true,
    spaceBetween: 20,

    navigation: {
      nextEl: '.reviews__slider .swiper-button-next',
      prevEl: '.reviews__slider .swiper-button-prev',
    },

    breakpoints: {
      768: {
        // slidesPerView: 2,
        spaceBetween: 80,
      },
      1200: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
    },
  });
};

const initReviewsHeight = () => {
  document.addEventListener('DOMContentLoaded', () => {
    const items = document.querySelectorAll<HTMLDivElement>('.reviews__slide');

    let height = 120;
    if (window.innerWidth >= 768) {
      height = 120;
    }

    items.forEach((item) => {
      const p = item.querySelector<HTMLParagraphElement>('.reviews__text');
      if (p!.offsetHeight > height) {
        item.classList.add('cut');
      }
    });
  });
};

const initMoreButtons = () => {
  const modal = document.querySelector<HTMLDivElement>('.modal-review');
  const grade = modal?.querySelector<HTMLHeadingElement>('.review__grade');
  const text = modal?.querySelector<HTMLHeadingElement>('.review__text');
  const name = modal?.querySelector<HTMLParagraphElement>('.review__name');
  const city = modal?.querySelector<HTMLParagraphElement>('.review__city');
  const buttons = document.querySelectorAll<HTMLButtonElement>(
    '.reviews__more'
  );
  buttons.forEach((item) => {
    item.addEventListener('click', () => {
      grade!.textContent = `Оценка: ${item.dataset.grade!}`;
      text!.textContent = item.dataset.text!;
      name!.textContent = item.dataset.name!;
      city!.textContent = item.dataset.city!;

      modal?.classList.add('active');
      document.body.classList.add('overflow-hidden');
    });
  });
};

let rangeSlider: any;
const initGradeRange = () => {
  const sliderEl = document.querySelector<HTMLDivElement>(
    '.add-review__grade-range'
  );
  rangeSlider = noUiSlider.create(sliderEl!, {
    start: 5,
    step: 1,
    connect: 'lower',
    range: {
      min: 1,
      max: 5,
    },
  });

  const grades = document.querySelectorAll<HTMLSpanElement>(
    '.add-review__grade-scale span'
  );
  grades.forEach((item) => {
    item?.addEventListener('click', () => {
      rangeSlider.set(+item.textContent!);
    });
  });
};

const initAddReview = () => {
  const thanksReview = document.querySelector<HTMLDivElement>(
    '.modal-thanks-review'
  );
  const modal = document.querySelector<HTMLDivElement>('.modal-add-review');
  const form = document.querySelector<HTMLFormElement>('.add-review');
  const checkbox = form?.querySelector<HTMLInputElement>(
    'input[type="checkbox"]'
  );
  const nameInput = document.querySelector<HTMLInputElement>(
    '.add-review__name input'
  );
  const emailInput = document.querySelector<HTMLInputElement>(
    '.add-review__email input'
  );
  const cityInput = document.querySelector<HTMLInputElement>(
    '.add-review__city input'
  );
  cityInput?.addEventListener('focus', () => {
    if (!cityInput.value) {
      cityInput.value = 'г. ';
    }
  });

  const textInput = document.querySelector<HTMLTextAreaElement>(
    '.add-review__text textarea'
  );
  const button = document.querySelector<HTMLButtonElement>('.reviews__add');
  button?.addEventListener('click', () => {
    modal?.classList.add('active');
    document.body.classList.add('overflow-hidden');
  });

  const getSubmitFormData = () => {
    const formData = new FormData();
    formData.append('grade', rangeSlider.get());
    formData.append('name', nameInput!.value);
    formData.append('email', nameInput!.value);
    formData.append('city', cityInput!.value);
    formData.append('text', textInput!.value);
    return formData;
  };

  const resetForm = () => {
    nameInput!.value = '';
    emailInput!.value = '';
    cityInput!.value = '';
    textInput!.value = '';
    checkbox!.checked = false;
  };

  form?.addEventListener('submit', async (event) => {
    event.preventDefault();
    const formData = getSubmitFormData();
    await fetch('/ajax/addReview.php', {
      method: 'POST',
      body: formData,
    });
    resetForm();
    modal?.classList.remove('active');
    thanksReview?.classList.add('active');
    setTimeout(() => {
      thanksReview?.classList.remove('active');
      document.body.classList.remove('overflow-hidden');
    }, 3000);
  });
};

export default {
  init: () => {
    initReviewsSlider();
    initReviewsHeight();
    initMoreButtons();
    initGradeRange();
    initAddReview();
  },
};
