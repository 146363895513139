import GLightbox from 'glightbox';

const initZoom = () => {
  const lightbox = GLightbox({
    selector: '.gallery__image',
    loop: true,
  });
};

export default {
  init: () => {
    initZoom();
  },
};
