import './styles/main.scss';

import header from './scripts/header';
import about from './scripts/about';
import gallery from './scripts/gallery';
import rooms from './scripts/rooms';
import question from './scripts/question';
import reviews from './scripts/reviews';
import quiz from './scripts/quiz';
import footer from './scripts/footer';
import modals from './scripts/modals';
import callback from './scripts/callback';
import booking from './scripts/booking';
import cookie from './scripts/cookie';

header.init();
about.init();
gallery.init();
rooms.init();
question.init();
reviews.init();
quiz.init();
footer.init();

modals.init();
callback.init();
booking.init();
cookie.init();

document.addEventListener('DOMContentLoaded', () =>
  document.body.classList.add('loaded')
);
